import React, { useState } from 'react';
import Hero from '../../components/Hero';
import { SideBySide, SideByQuote, TopHeading } from '../../components/Blocks';
// import OpenConsulting from './OpenConsulting';
import heroImg from './assets/air-cargo-freight-azur-carrying-personal-protection-equipment.jpeg';
import blockOne from './assets/aircraft-cargo-freight-air-logistics.jpg';
import blockTwo from './assets/cargo-ship-carrying-containers-sea-logistics.jpg';
import blockThree from './assets/truck-road-freight-transporting-goods-on-a-container.jpg';
import './WhatWeDo.css';
import { Trans } from '@lingui/macro';
import { Box } from 'rebass';

const WhatWeDo = () => {
  const [expandedBlocks, setExpanded] = useState([]);

  function toggleBlock(blockId) {
    const currentIndex = expandedBlocks.indexOf(blockId);
    if (currentIndex === -1) {
      // if not in index means is collapsed
      setExpanded([...expandedBlocks, blockId]);
    } else {
      // if in index we remove it
      setExpanded([
        ...expandedBlocks.slice(0, currentIndex),
        ...expandedBlocks.slice(currentIndex + 1),
      ]);
    }
  }

  function closeBlock(blockId) {
    const currentIndex = expandedBlocks.indexOf(blockId);

    if (currentIndex !== -1) {
      setExpanded([
        ...expandedBlocks.slice(0, currentIndex),
        ...expandedBlocks.slice(currentIndex + 1),
      ]);
    }
  }

  return (
    <div>
      <header>
        <Hero src={[heroImg]} alt="A cargo aircraft for logistics beeing unloaded with personal protection equipment (masks, globes) at the airport tarmac" />
      </header>
      <Box as="section" pt={4}>
        <TopHeading
          negative
          sx={{
            mb: 4,
          }}
          title={<Trans id="wwd.title">What we do</Trans>}
          subtitle={
            <Trans id="wwd.subtitle">
              A boutique firm offering private aviation expertise.
            </Trans>
          }
          body={
            <Trans id="wwd.body">
              We accompany individuals, corporations, governmental organisations
              and many more through every step of acquiring or selling a single
              aircraft or fleet from a 4-seater very light jet to corporate
              airlines. As a boutique firm we are able to offer personalised
              consultancy services to private jet buyers, sellers and traders.
              For all transactions and dealings, you can count on our utmost
              discretion.
            </Trans>
          }
        />
      </Box>
      <section className="block">
        <SideByQuote
          title={
            <Trans id="wwd.quote.title">Transactions based on trust</Trans>
          }
          quote={
            <Trans id="wwd.quote.sentence">
              The world of private jet transactions is largely based on
              relationships of trust. Trust that has been purposefully earned
              over time
            </Trans>
          }
          author={
            <Trans id="wwd.quote.author">
              - Massimo Burotti, Head of Sales and Acquisitions
            </Trans>
          }
          body={
            <Trans id="wwd.quote.body">
              LunaSolutions is built on a decade-long foundation of corporate
              aviation history. Broad business acumen coupled with deep
              professional connections (specialised aviation lawyers, aircraft
              inspectors, financing institutions, aircraft management operators,
              aircraft manufacturers, other sales and acquisitions brokers) make
              us particularly qualified to help you complete the sale or
              acquisition of your aircraft.
            </Trans>
          }
        />
        <SideBySide
          reverse
          alt="Ground Support Equipment (GSE) supporting the cargo goods loading operations of a container and a pallet of an aircraft on the ground of the airport tarmac thanks to a Container Loader. Plane lateral door is opened to allow the goods to get in. A men brings the load of goods to be accomodated inside the aircraft trunk"
          image={blockOne}
          title={<Trans id="wwd.service.1.title">Consulting</Trans>}
          // button={{
          //   intent: 'toggle',
          //   active: expandedBlocks.includes('consulting'),
          //   text: <Trans>More Information</Trans>,
          //   onClick: () => toggleBlock('consulting'),
          // }}
          body={
            <Trans id="wwd.service.1.body">
              Before acquiring an aircraft—especially for the first time—clients
              turn to LunaSolutions with specific questions ranging from type of
              ownership, to refurbishments, onto operational options. Our
              aviation experts will guide and advise you through the
              decision-making process and provide timely and precise answers to
              all your queries.
            </Trans>
          }
          // expanded={expandedBlocks.includes('consulting')}
          // expansion={
          //   <OpenConsulting
          //     id="consulting"
          //     onClose={closeBlock}
          //     specs={[
          //       {
          //         title: (
          //           <Trans id="wwd.service.1.spec.1.title">
          //             Defining your needs
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.1.spec.1.body">
          //             Before acquiring a business jet, one of the first steps is
          //             understanding your needs and intended future usage of your
          //             plane and its crew. We will then produce a cost benefit
          //             analysis and focus in on the most appropriate private jet.
          //           </Trans>
          //         ),
          //       },
          //       {
          //         title: (
          //           <Trans id="wwd.service.1.spec.2.title">
          //             Ownership analysis
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.1.spec.2.body">
          //             LunaSolutions can help you understand the ramifications of
          //             full ownership, partnerships and other forms of ownership.
          //           </Trans>
          //         ),
          //       },
          //       {
          //         title: (
          //           <Trans id="wwd.service.1.spec.3.title">
          //             Fleet assessment
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.1.spec.3.body">
          //             LunaSolutions is on hand to assess the fleet of
          //             individuals or organisations who own multiple aircraft in
          //             order to provide operational, financial and fiscal advice
          //             to maximise the returns of such important investments.
          //           </Trans>
          //         ),
          //       },
          //       {
          //         title: (
          //           <Trans id="wwd.service.1.spec.4.title">
          //             Personalisation, enhancements and delivery options
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.1.spec.4.body">
          //             Whether it is a factory-new plane that you wish to
          //             personalise or a pre-owned aircraft in need of
          //             refurbishing, our aviation experts will be happy to steer
          //             you through these important and exciting decisions.
          //           </Trans>
          //         ),
          //       },
          //       {
          //         title: (
          //           <Trans id="wwd.service.1.spec.5.title">
          //             Operational Advice
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.1.spec.5.body">
          //             Thanks to its extensive experience with operators around
          //             the globe, LunaSolutions is ideally equipped to provide
          //             you with frank and helpful operational advice when owning
          //             an aircraft. If you are considering offering your aircraft
          //             for charter, we can provide you with real-life, realistic
          //             forecasts on expected returns.
          //           </Trans>
          //         ),
          //       },
          //     ]}
          //   />
          // }
        />

        <SideBySide
          alt="Cargo ship being loaded on the martime port to carry containers on the sea. A maritime crane is loading a container to the cargo ship on the dock."
          image={blockTwo}
          title={<Trans id="wwd.service.2.title">Aircraft Sales</Trans>}
          // button={{
          //   intent: 'toggle',
          //   active: expandedBlocks.includes('sales'),
          //   text: <Trans>More Information</Trans>,
          //   onClick: () => toggleBlock('sales'),
          // }}
          body={
            <Trans id="wwd.service.2.body">
              LunaSolutions will accompany you through the entire process of
              selling your aircraft. The initial step in the sales process will
              be for us to provide you with the market price of your private
              jet. We will then work hand in hand with you to elaborate
              appropriate marketing strategies, find interested buyers and
              negotiate on your behalf the final transaction.
            </Trans>
          }
          // expanded={expandedBlocks.includes('sales')}
          // expansion={
          //   <OpenConsulting
          //     id="sales"
          //     onClose={closeBlock}
          //     specs={[
          //       {
          //         title: (
          //           <Trans id="wwd.service.2.spec.1.title">
          //             Locating potential buyers
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.2.spec.1.body">
          //             <p>
          //               Over the years, LunaSolutions has not only developed
          //               first-rate savoir faire in the resale of aircraft but
          //               has also cultivated a comprehensive network of potential
          //               business jet buyers. Whether you want us to openly
          //               market your aircraft, or sell it through the
          //               “off-market”, our sales team will work in a manner that
          //               fully respects your wish for privacy and discretion.
          //             </p>

          //             <p>
          //               LunaSolutions will compile all the necessary
          //               documentation required to present your plane to
          //               interested buyers. We will produce detailed specs of
          //               your aircraft to highlight its features and statistics
          //               in order to effectively market it.
          //             </p>
          //           </Trans>
          //         ),
          //       },
          //       {
          //         title: (
          //           <Trans id="wwd.service.2.spec.2.title">
          //             Finalizing the sale of your aircraft
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.2.spec.2.body">
          //             <p>
          //               There are numerous details and timely actions that we
          //               will gladly handle for you in the final phase of the
          //               transaction. These actions can include the need to:
          //             </p>
          //             <ul>
          //               <li>Screen demands from potential buyers </li>
          //               <li>Evaluate the validity of offers</li>
          //               <li>Write up contractual agreements</li>
          //               <li>Set up escrow account</li>
          //               <li>Organise pre-buy inspections</li>
          //               <li>Manage the de-registration </li>
          //               <li>Make delivery arrangements</li>
          //             </ul>
          //           </Trans>
          //         ),
          //       },
          //     ]}
          //   />
          // }
        />
        <SideBySide
          reverse
          alt="A truck is moving and carrying a container on a road. The truck is used to move road freight."
          image={blockThree}
          title={<Trans id="wwd.service.3.title">Aircraft Acquisition</Trans>}
          // button={{
          //   intent: 'toggle',
          //   active: expandedBlocks.includes('aquisition'),
          //   text: <Trans>More Information</Trans>,
          //   onClick: () => toggleBlock('aquisition'),
          // }}
          body={
            <Trans id="wwd.service.3.body">
              At LunaSolutions we solely represent you at every step of the
              acquisition process, independently and transparently, with no
              conflict of interest.
            </Trans>
          }
          // expanded={expandedBlocks.includes('aquisition')}
          // expansion={
          //   <OpenConsulting
          //     id="aquisition"
          //     onClose={closeBlock}
          //     specs={[
          //       {
          //         title: (
          //           <Trans id="wwd.service.3.spec.1.title">
          //             Finding your aircraft
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.3.spec.1.body">
          //             <p>
          //               We can assist governments, corporations and individuals
          //               with the purchase of a factory-new aircraft or a
          //               pre-owned plane. And thanks to our extensive network in
          //               the aviation sector and personal relationships built
          //               over the years, we have exclusive access to off-market
          //               aircraft that are not advertised.
          //             </p>
          //             <p>
          //               Our experienced team of experts will conduct a
          //               meticulous review and analysis of the aircraft you wish
          //               to acquire, considering its current state and its
          //               history. In particular, we will:
          //             </p>
          //             <ul>
          //               <li>Consider the aircraft’s maintenance history</li>
          //               <li>Audit the flight blog</li>
          //               <li>Conduct a pre-purchase inspection</li>
          //               <li>Organise a test flight</li>
          //               <li>Propose possible enhancements</li>
          //             </ul>
          //           </Trans>
          //         ),
          //       },
          //       {
          //         title: (
          //           <Trans id="wwd.service.3.spec.2.title">
          //             Finalizing the purchase of your aircraft
          //           </Trans>
          //         ),
          //         body: (
          //           <Trans id="wwd.service.3.spec.2.body">
          //             <p>
          //               Our years of experience allow us to provide our clients
          //               with the accurate market price of the aircraft and an
          //               unbiased estimation of its value over the years to come.
          //               You can count on LunaSolutions to:
          //             </p>

          //             <ul>
          //               <li>Negotiate price </li>
          //               <li>Source financing</li>
          //               <li>Review and amend contracts</li>
          //               <li>Troubleshoot and resolve last minute issues</li>
          //               <li>Supervise the legal process</li>
          //               <li>
          //                 Organise delivery options (anywhere in the world)
          //               </li>
          //             </ul>
          //             <p>
          //               At LunaSolutions we are passionate about planes, but we
          //               never forget to focus first and foremost on our clients.
          //               We not only wish to fulfil your dreams, but we also want
          //               to do so in a manner that will remove any stress or
          //               pressure on you, the buyer.
          //             </p>
          //           </Trans>
          //         ),
          //       },
          //     ]}
          //   />
          // }
        />
      </section>
    </div>
  );
};

export default WhatWeDo;
