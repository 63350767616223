// example theme.js

const btnStyle = {
    apperance: 'none',
    borderRadius: 0,
    alignItems: 'center',
    display: 'flex',
    textTransform: 'uppercase',
    fontFamily: 'Raleway',
    fontWeight: 600,
    paddingX: '20px',
    paddingY: '10px',
    border: 0,
    cursor: 'pointer',
    fontSize: 14,
    textDecoration: 'none',
    color: 'white',
    backgroundColor: 'blue',
    '&.active': {
        backgroundColor: 'white',
        color: 'blue',
        boxShadow: 'btn',
        '> svg': {
            fill: 'blue',
        },
    },
    '&.disabled': {
        cursor: 'not-allowed',
    },
    ':hover': {
        backgroundColor: 'white',
        color: 'blue',
        boxShadow: 'btn',
        '> svg': {
            fill: 'blue',
        },
    },
    '> svg': {
        fill: 'white',
        marginRight: 10,
        width: '12px',
        height: '12px',
    },
};

const btnCloseStyle = {
    apperance: 'none',
    borderRadius: 0,
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Raleway',
    fontWeight: 600,
    paddingX: '20px',
    paddingY: '10px',
    border: 0,
    cursor: 'pointer',
    fontSize: 14,
    textDecoration: 'none',
    color: 'white',
    backgroundColor: '#212629',
    '> svg': {
        fill: 'white',
        marginRight: 10,
        width: '25px',
        height: '25px',
    },
};

export default {
    breakpoints: ['411px', '992px'],
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 80],
    colors: {
        blue: '#2d3d51',
        lightgrey: '#929292',
        black: '#000',
        darkgrey: '#2a2d2e',
    },
    space: [0, 5, 10, 20, 40, 80, 160, 320],
    fonts: {
        body: 'Raleway',
        heading: 'Lato',
        monospace: 'Menlo, monospace',
    },
    fontWeights: {
        body: 100,
        heading: 200,
        bold: 700,
    },
    lineHeights: {
        body: 1.6,
        heading: 1.25,
    },
    shadows: {
        btn: 'inset 0 0 0 2px #2d3d51',
    },
    forms: {
        dropdownAircraftFilter: {
            border: 0,
            borderBottom: '1px solid #d5d5d5',
            borderRadius: 0,
            fontFamily: 'body',
            fontSize: 1,
            fontWeight: '600',
            textTransform: 'uppercase',
        },
        inputAircraftFilter: {
            border: 0,
            borderBottom: '1px solid #d5d5d5',
            borderRadius: 0,
            fontFamily: 'body',
            fontSize: 1,
            apperance: 'none',
            fontWeight: '600',
            height: '39px',
            textTransform: 'uppercase',
            '::placeholder': {
                color: 'white',
                opacity: 1,
            },
        },
        labelContactUs: {
            color: '#2d3d51',
            fontWeight: 500,
            paddingTop: '8px',
            paddingBottom: '8px',
        },
        inputContactUs: {
            color: '#2d3d51',
            fontWeight: 500,
            backgroundColor: '#f7f7f7',
            borderColor: 'transparent',
            borderWidth: 2,
            fontSize: 1,
            '&:focus': {
                backgroundColor: '#fff',
                borderColor: '#f7f7f7',
            },
        },
    },
    variants: {
        cta: btnStyle,
        footerLink: {
            color: 'darkgrey',
            textDecoration: 'none',
            fontWeight: 500,
            fontSize: 1,
            display: 'flex',
            opacity: 0.8,
            alignItems: 'center',
            marginY: 3,
            flexWrap: 'nowrap',
            '&:hover': {
                textDecoration: 'underline',
            },
            '> svg': {
                marginRight: '10px',
            },
        },
    },
    text: {
        aircraftCardDetail: {
            textTransform: 'uppercase',
            fontFamily: 'Lato',
            fontWeight: '400',
            color: 'lightgrey',
            fontSize: '12px',
        },
        aircraftCardTitle: {
            textTransform: 'uppercase',
            fontFamily: 'Lato',
            fontWeight: '700',
            color: 'darkgrey',
            fontSize: 3,
        },
        aircraftCardSubTitle: {
            textTransform: 'uppercase',
            fontFamily: 'Lato',
            fontWeight: '600',
            color: 'lightgrey',
            fontSize: 2,
        },
        footerTitle: {
            fontFamily: 'Raleway',
            fontWeight: 600,
            fontSize: 2,
            color: 'blue',
        },
        footerText: {
            fontFamily: 'Raleway',
            fontWeight: 500,
            fontSize: 1,
            lineHeight: 'body',
            color: 'darkgrey',
            opacity: 0.8,
        },
        blockHeading: {
            fontSize: [5, 6],
            fontFamily: 'heading',
            fontWeight: 'heading',
            color: 'darkgrey',
        },
        blockSubHeading: {
            fontSize: [1, 2, 2],
            fontFamily: 'body',
            fontWeight: 'body',
            color: 'black',
            lineHeight: 'body',
        },
        blockText: {
            fontFamily: 'body',
            fontWeight: 'body',
            lineHeight: 'body',
            color: 'darkgrey',
            fontSize: 1,
        },
        blockQuote: {
            fontFamily: 'body',
            fontWeight: 400,
            lineHeight: 'body',
            fontStyle: 'italic',
            color: 'white',
            fontSize: 4,
        },
        blockAuthorQuote: {
            fontFamily: 'body',
            fontWeight: 400,
            lineHeight: 'body',
            fontStyle: 'italic',
            color: '#7e8489',
        },
        blockOpenContentHeading: {
            fontSize: 2,
            fontFamily: 'heading',
            fontWeight: 700,
            textTransform: 'uppercase',
            color: 'white',
            lineHeight: 'body',
        },
        blockTextContentOpenWhite: {
            fontFamily: 'body',
            fontWeight: 100,
            lineHeight: 'body',
            color: 'white',
            fontSize: 1,
        },
        newsCardBody: {
            lineHeight: 'body',
            fontWeight: 100,
            fontSize: 1,
        },
        newsCardHeading: {
            lineHeight: 'body',
            fontWeight: 100,
            fontSize: [4, 4, 5],
        },
        contactUsDetail: {
            fontFamily: 'Raleway',
            fontWeight: 600,
            fontSize: 3,
            color: 'blue',
        },
    },
    buttons: {
        primary: btnStyle,
        second: btnCloseStyle,
        // mobileIcon
        aircraftFilterBtn: {
            background: 'transparent',
            padding: 0,
            '> svg': {
                height: '64px',
                width: '64px',
            },
        },
    },
};