import React from 'react';
import Hero from '../../components/Hero';
import ContactForm from '../../components/ContactForm';
import { PhoneIcon, MailIcon, PinIcon } from '../../components/Icons';
import { Flex, Box, Link, Text, Image } from 'rebass';
import heroImg from './assets/air-cargo-freight-boeing-777-300-er-aircraft.jpeg';
import geneva from './assets/lunalogistik-office-geneva-switzerland.png';
import london from './assets/lunalogistik-office-london-uk.png';
import './ContactUs.css';
import { Trans } from '@lingui/macro';
import { TopHeading } from '../../components/Blocks';
import { offices } from '../../config';

const ContactUs = () => {
  return (
    <div>
      <header>
        <Hero src={[heroImg]} alt="Two cargo containers in front of an aircraft boeing 777 300 ER that is waiting in the airport tarmac." />
        <TopHeading
          title={<Trans id="contactUs.title">Contact Us</Trans>}
          subtitle={<Trans id="contactUs.subtitle">Submit an enquiry</Trans>}
          body={
            <Trans id="contactUs.body">
              If you would like further information on the services we offer, or
              if you are interested in acquiring or selling a corporate jet,
              please feel free to contact us.
            </Trans>
          }
        />
      </header>

      <section className="block">
        {/* Offices */}
        <Flex className="offices" flexWrap="wrap" justifyContent="center">
          <Box
            mb={[40, 40, 0]}
            width={[1, 1, 1 / 4]}
            className="office-wrapper"
          >
            <Flex
              flexWrap="wrap"
              justifyContent={['center', 'center', 'flex-start']}
              alignItems={['center', 'center', 'flex-start']}
              className="office"
              flexDirection="column"
            >
              <Box
                justifyContent={['center', 'center', 'flex-start']}
                textAlign={['center', 'center', 'left']}
                mb={[15, 15, 30]}
              >
                <Image
                  src={geneva}
                  alt="Geneva lake in Switzerland with the water jet"
                  sx={{
                    width: ['80%', '60%', '60%'],
                  }}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Box mb={[15, 15, 30]}>
                <Text as="h2" variant="contactUsDetail">
                  <Trans>Geneva office</Trans>
                </Text>
              </Box>
              <Box mb={'10px'} className="contact-info">
                <Link
                  href={`geo:${offices.geneva.geo.lat},${offices.geneva.geo.long}`}
                  rel="noopener"
                  variant="footerLink"
                  sx={{ marginY: '5px' }}
                >
                  <PinIcon style={{ width: 18, height: 18 }} />
                  <span>{offices.geneva.address}</span>
                </Link>
              </Box>
              <Box mb={'10px'} className="contact-info">
                <Link
                  href={`tel:${offices.geneva.phone}`}
                  rel="noopener"
                  variant="footerLink"
                  sx={{ margin: '5px' }}
                >
                  <PhoneIcon style={{ width: 18, height: 18 }} />
                  <span>{offices.geneva.phone}</span>
                </Link>
              </Box>
              <Box mb={'10px'} className="contact-info">
                <Link
                  href={`mailto:${offices.geneva.email}`}
                  rel="noopener"
                  variant="footerLink"
                  sx={{ margin: '5px' }}
                >
                  <MailIcon style={{ width: 18, height: 18 }} />
                  <span>{offices.geneva.email}</span>
                </Link>
              </Box>
            </Flex>
          </Box>

          <Box width={[1, 1, 1 / 4]} className="office-wrapper">
            <Flex
              flexWrap="wrap"
              justifyContent={['center', 'center', 'flex-start']}
              alignItems={['center', 'center', 'flex-start']}
              className="office"
              flexDirection="column"
            >
              <Box
                justifyContent={['center', 'center', 'flex-start']}
                textAlign={['center', 'center', 'left']}
                mb={[15, 15, 30]}
              >
                <Image
                  src={london}
                  alt="Luna Logistik office in London, United Kingdom"
                  sx={{
                    width: ['80%', '60%', '60%'],
                  }}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </Box>
              <Box mb={[15, 15, 30]}>
                <Text as="h2" variant="contactUsDetail">
                  <Trans>London office</Trans>
                </Text>
              </Box>
              <Box mb={'10px'} className="contact-info">
                <Link
                  href={`geo:${offices.london.geo.lat},${offices.london.geo.long}`}
                  rel="noopener"
                  variant="footerLink"
                  sx={{ margin: '5px' }}
                >
                  <PinIcon style={{ width: 18, height: 18 }} />
                  <span>{offices.london.address}</span>
                </Link>
              </Box>
              <Box mb={'10px'} className="contact-info">
                <Link
                  href={`tel:${offices.london.phone}`}
                  rel="noopener"
                  variant="footerLink"
                  sx={{ margin: '5px' }}
                >
                  <PhoneIcon style={{ width: 18, height: 18 }} />
                  <span>{offices.london.phone}</span>
                </Link>
              </Box>
              <Box mb={'10px'} className="contact-info">
                <Link
                  href={`mailto:${offices.london.email}`}
                  rel="noopener"
                  variant="footerLink"
                  sx={{ margin: '5px' }}
                >
                  <MailIcon style={{ width: 18, height: 18 }} />
                  <span>{offices.london.email}</span>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Flex>

        {/* Contact Form */}
        <Flex
          className="offices"
          flexWrap="wrap"
          justifyContent="center"
          mt={4}
        >
          <Box width={[1, 1, 1 / 2]}>
            <ContactForm />
          </Box>
        </Flex>
      </section>
    </div>
  );
};

export default ContactUs;
