import React, { useState } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import Image from '../Image';
import Button from '../Button';
import PropTypes from 'prop-types';

const SideBySide = React.forwardRef(
  (
    { reverse, image, title, subtitle, body, button, expanded, expansion, alt },
    ref,
  ) => {
    return (
      <>
        <Flex ref={ref} sx={{ paddingX: [10, 10, 0] }} flexWrap="wrap">
          <Box
            width={[1, 1, 1 / 2]}
            order={[1, 1, reverse ? 1 : 0]}
            justifyContent="center"
            display="flex"
          >
            <Flex
              flexWrap="wrap"
              sx={{
                height: ['auto', 'auto', 'auto'],
              }}
              justifyContent="center"
              alignItems={[
                'center',
                'center',
                reverse ? 'flex-start' : 'flex-end',
              ]}
              flexDirection="column"
            >
              <Box
                width={[1, 1, 1 / 2]}
                padding={[20, 20, 0]}
                justifyContent="center"
                marginRight={['unset', 'unset', reverse ? 'unset' : '10%']}
                marginLeft={['unset', 'unset', reverse ? '10%' : 'unset']}
              >
                <Heading
                  variant="blockHeading"
                  as="h2"
                  textAlign={['center', 'center', 'left']}
                >
                  {title}
                </Heading>

                <Text
                  variant="blockSubHeading"
                  textAlign={['center', 'center', 'left']}
                  as="h3"
                >
                  {subtitle}
                </Text>

                <Box
                  as="hr"
                  sx={{
                    margin: [
                      '20px auto',
                      '20px 0 !important',
                      '20px 0 !important',
                    ],
                    width: '20px',
                    backgroundColor: 'black',
                  }}
                />

                <Box>
                  <Text
                    variant="blockText"
                    textAlign="justify"
                    marginBottom="20px"
                    textAlign={['center', 'center', 'justify']}
                  >
                    {body}
                  </Text>

                  { button ?  <Flex justifyContent={['center', 'center', 'start']}>
                    <Box>
                      <Button {...button} />
                    </Box>
                  </Flex> : null }
                </Box>
              </Box>
            </Flex>
          </Box>
          <Box width={[1, 1, 1 / 2]} order={[0, 0, reverse ? 0 : 1]}>
            <Image
              alt={alt}
              src={image}
              ratio="4/3"
              sx={{
                width: ['100%'],
              }}
              style={{
                objectFit: 'cover',
              }}
            />
          </Box>
        </Flex>
        {expanded && expansion ? expansion : null}
      </>
    );
  },
);

SideBySide.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  reverse: PropTypes.bool,
  expanded: PropTypes.bool,
  expansion: PropTypes.node,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    }),
  ]).isRequired,
  button: PropTypes.shape(Button.propTypes),
};

export default SideBySide;
