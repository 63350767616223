import React from 'react';
import isArray from 'lodash/isArray';
import './hero.css';

const Hero = ({ src, alt }) => {
  return (
    <picture id="hero">
      {isArray(src)
        ? src.map((source, index) => (
            <source
              key={`${source}-${index}`}
              srcSet={source}
              media={index === 0 ? '(orientation: portrait)' : ''}
            />
          ))
        : null}
      <img
        src={isArray(src) ? src[src.length - 1] : src}
        alt={alt}
      />
    </picture>
  );
};

export default Hero;
