import React, { useContext } from 'react';
import { Flex, Box } from 'rebass';
import { Label, Input, Textarea } from '@rebass/forms';
import Button from '../Button';
import { t, Trans } from '@lingui/macro';
import './ContactForm.css';
import { Formik } from 'formik';
import { withI18n } from '@lingui/react';
import cx from 'classnames';
import { AppContext } from '../App/App';

const INITIAL_VALUES = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: '',
};

const validateEmail = email => {
  const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return EMAIL_PATTERN.test(String(email).toLowerCase());
};

const ContactForm = ({ i18n }) => {
  const { fetch } = useContext(AppContext);

  const onSubmit = async (values, bag) => {
    // console.log(values, bag);

    bag.setSubmitting(true);
    const data = await fetch('/contact-us', {
      method: 'post',
      body: JSON.stringify(values),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(res => {
      if (res.status === 200) {
        bag.setSubmitting(false);
        bag.resetForm({
          values: INITIAL_VALUES,
          status: {
            ok: true,
          },
        });
      }
    });
  };

  const onValidate = values => {
    let errors = {};

    if (!values.name) errors.name = i18n._(t`Required Field`);
    if (!values.email) errors.email = i18n._(t`Required Field`);
    if (!values.subject) errors.subject = i18n._(t`Required Field`);
    if (!values.message) errors.message = i18n._(t`Required Field`);
    if (values.email && !validateEmail(values.email))
      errors.email = i18n._(t`Invalid Email format`);

    return errors;
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validate={onValidate}
      initialValues={INITIAL_VALUES}
      validateOnBlur={true}
      validateOnChange={true}
      validateOnMount={false}
    >
      {props => {
        const nameHasError = props.touched.name && props.errors.name;
        const emailHasError = props.touched.email && props.errors.email;
        const subjectHasError = props.touched.subject && props.errors.subject;
        const messageHasError = props.touched.message && props.errors.message;

        const nameIsValid = props.touched.name && !props.errors.name;
        const emailIsValid = props.touched.email && !props.errors.email;
        const subjectIsValid = props.touched.subject && !props.errors.subject;
        const messageIsValid = props.touched.message && !props.errors.message;

        return (
          <Box as="form" onSubmit={props.handleSubmit} py={5} pt={0}>
            <Flex flexWrap="wrap" py={[0, 0, 1]}>
              <Box
                width={[1, 1, 1 / 3]}
                px={2}
                className={cx(
                  'contact-box',
                  nameHasError ? 'error' : nameIsValid ? 'valid' : null,
                )}
              >
                <Label htmlFor="name" variant="labelContactUs">
                  Name
                </Label>
                <Box className="input-wrapper">
                  <Input
                    variant="inputContactUs"
                    id="name"
                    name="name"
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Box>

                <span className="error">{nameHasError}</span>
              </Box>

              <Box
                width={[1, 1, 1 / 3]}
                px={2}
                className={cx(
                  'contact-box',
                  emailHasError ? 'error' : emailIsValid ? 'valid' : null,
                )}
              >
                <Label htmlFor="email" variant="labelContactUs">
                  E-mail adress
                </Label>
                <Box className="input-wrapper">
                  <Input
                    variant="inputContactUs"
                    id="email"
                    value={props.values.email}
                    name="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Box>
                <span className="error">{emailHasError}</span>
              </Box>

              <Box width={[1, 1, 1 / 3]} px={2} className={cx('contact-box')}>
                <Label htmlFor="phone" variant="labelContactUs">
                  Phone number
                </Label>
                <Box className="input-wrapper">
                  <Input
                    variant="inputContactUs"
                    id="phone"
                    name="phone"
                    value={props.values.phone}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Box>
              </Box>
            </Flex>

            <Flex flexWrap="wrap" py={[0, 0, 1]}>
              <Box
                width={[1, 1, 2 / 3]}
                px={2}
                className={cx(
                  'contact-box',
                  subjectHasError ? 'error' : subjectIsValid ? 'valid' : null,
                )}
              >
                <Label htmlFor="subject" variant="labelContactUs">
                  Subject
                </Label>
                <Box className="input-wrapper">
                  <Input
                    variant="inputContactUs"
                    id="subject"
                    name="subject"
                    value={props.values.subject}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />
                </Box>

                <span className="error">{subjectHasError}</span>
              </Box>
            </Flex>

            <Flex flexWrap="wrap" py={[0, 0, 1]}>
              <Box
                width={[1, 1, 1]}
                px={2}
                className={cx(
                  'contact-box',
                  messageHasError ? 'error' : messageIsValid ? 'valid' : null,
                )}
              >
                <Label htmlFor="message" variant="labelContactUs">
                  Message
                </Label>
                <Box className="input-wrapper">
                  <Textarea
                    variant="inputContactUs"
                    id="message"
                    name="message"
                    minHeight="200px"
                    value={props.values.message}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    sx={{
                      resize: 'vertical',
                    }}
                  />
                </Box>
                <span className="error">{messageHasError}</span>
              </Box>
            </Flex>

            <Flex
              width={[1, 1, 1]}
              justifyContent={['center', 'center', 'flex-end']}
              py={[3, 3, 1]}
              px={2}
            >
              {props.status && props.status.ok ? (
                <span
                  style={{
                    display: 'flex',
                    flex: 1,
                    alignItems: 'center',
                    color: '#51ba65',
                  }}
                >
                  <Trans id="message-sent">Your message has been sent!</Trans>
                </span>
              ) : null}
              <Box className="send-button">
                <Button
                  id="contact-us-btn-submit"
                  disabled={!props.isValid && !props.isSubmitting}
                  type="submit"
                  loading={props.isSubmitting}
                  text={<Trans id="send-message">Send Message</Trans>}
                  rel="noopener"
                />
              </Box>
            </Flex>
          </Box>
        );
      }}
    </Formik>
  );
};

export default withI18n()(ContactForm);
