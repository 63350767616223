import React, { useState } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import Image from '../Image';
import Button from '../Button';
import quote1 from './assets/quote_1.png';
import quote2 from './assets/quote_2.png';

const SideByQuote = ({
  reverse,
  title,
  subtitle,
  body,
  quote,
  author,
  button,
}) => {
  const [isActive, setActive] = useState(false);

  return (
    <Flex sx={{ paddingX: [10, 10, 0] }} flexWrap="wrap" mb="10%">
      <Box
        width={[1, 1, 1 / 2]}
        order={[1, 1, reverse ? 1 : 0]}
        sx={{ display: 'flex' }}
      >
        <Flex
          flexWrap="wrap"
          sx={{
            height: ['auto', 'auto', 'auto'],
          }}
          justifyContent="center"
          alignItems="flex-end"
          flexDirection="column"
          marginRight={['unset', 'unset', '10%']}
        >
          <Box
            width={[1, 1, 1 / 2]}
            padding={[20, 20, 0]}
            justifyContent="center"
          >
            <Heading
              variant="blockHeading"
              as="h2"
              textAlign={['center', 'center', 'left']}
            >
              {title}
            </Heading>

            <Text
              variant="blockSubHeading"
              textAlign={['center', 'center', 'left']}
            >
              {subtitle}
            </Text>

            <hr />

            <Box>
              <Text
                variant="blockText"
                textAlign="justify"
                marginBottom="20px"
                textAlign={['center', 'center', 'justify']}
              >
                {body}
              </Text>

              {button ? (
                <Flex justifyContent={['center', 'center', 'start']}>
                  <Box>
                    <Button {...button} rel="noopener" />
                  </Box>
                </Flex>
              ) : null}
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box width={[1, 1, 1 / 2]} order={[0, 0, reverse ? 0 : 1]}>
        <Flex
          flexWrap="wrap"
          sx={{
            height: ['auto', 'auto', 'auto'],
          }}
          justifyContent="center"
          alignItems="flex-start"
          flexDirection="column"
          marginLeft={['unset', 'unset', '10%']}
        >
          <Box
            width={[1, 1, '450px']}
            padding={[20, 20, 0]}
            justifyContent="center"
            bg="#28313b"
          >
            <Flex justifyContent={['center', 'center', 'center']}>
              <Box
                mt={['10%', '10%', '20%']}
                justifyContent={['center', 'center', 'center']}
              >
                <Image
                  src={quote1}
                  sx={{
                    width: ['100%'],
                  }}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Flex>
            <Text
              variant="blockQuote"
              textAlign="left"
              my="5%"
              mx={['8%', '10%', '15%']}
              textAlign={['left', 'left', 'left']}
            >
              {quote}
            </Text>
            <Text
              variant="blockAuthorQuote"
              textAlign="left"
              mb="10%"
              ml={['8%', '10%', '15%']}
              textAlign={['left', 'left', 'left']}
              fontSize={0}
            >
              {author}
            </Text>
            <Flex justifyContent={['center', 'center', 'center']}>
              <Box
                mb={['10%', '10%', '20%']}
                justifyContent={['center', 'center', 'center']}
              >
                <Image
                  src={quote2}
                  sx={{
                    width: ['100%'],
                  }}
                  style={{
                    objectFit: 'cover',
                  }}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default SideByQuote;
