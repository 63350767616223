// https://github.com/lifeomic/axios-fetch#support-for-ie11
import * as es6Promise from 'es6-promise';
import 'isomorphic-fetch';
import React from 'react';
import { hydrate } from 'react-dom';
import ApolloClient from 'apollo-boost';
import Root from './components/Root';
import configureStore from './store';
import axios from 'axios';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

const axiosInstance = axios.create({
  baseURL: '/',
  withCredentials: true,
});

global.fetch = buildAxiosFetch(axiosInstance, function(config) {
  config.timeout = 30000;
  config.headers = {
    ...config.headers,
    // 'X-From-Axios': 'its client!', // will show on 'Access-Control-Request-Headers'
  };
  return config;
});

const apolloClient = new ApolloClient({
  uri: '/graphql',
  credentials: 'include',
  fetch,
});

const reduxStore = configureStore(window.__REDUX_STATE__, {
  client: apolloClient,
  axios: fetch,
  fetch,

  // pass additional helpers to be used inside redux-thunk
});

hydrate(
  <Root client={apolloClient} store={reduxStore} fetch={fetch} />,
  document.getElementById('root'),
);

if (module.hot) {
  module.hot.accept();
}
