import Home from './pages/Home';
import WhatWeDo from './pages/WhatWeDo';
import WhoWeAre from './pages/WhoWeAre';
import ContactUs from './pages/ContactUs';
import { phone } from './config';

export const home = {
  path: '/',
  component: Home,
  exact: true,
  head: {
    title: 'Global Logistics Broker - Freight forwarders',
    description: `Global Expertise as leader logistics broker tailoring the services to your needs: air, land and maritime transport. Call us &#9990; ${phone}`,
  },
};

export const wwa = {
  path: '/en/offering-global-logistics-expertise',
  component: WhoWeAre,
  exact: true,
  head: {
    title: 'Global Multimodal Logistics Experts for You',
    description: `Global Expertise as leader logistics broker tailoring the services to your needs: air, land and maritime transports. Call us &#9990; ${phone}`,
  },
};

export const wwd = {
  path: '/en/multi-modal-transportation-logistics-solutions',
  component: WhatWeDo,
  exact: true,
  head: {
    title: 'Door to Door Logistics anywhere in the World',
    description: `Multi modal transports Services for your logistics, we have the reliable and cost efficient services that meets your needs. Call us &#9990; ${phone}`,
  },
};

export const contactUs = {
  path: '/en/contact-us-call-us',
  component: ContactUs,
  exact: true,
  head: {
    title: 'Contact our Team of Logistics Experts',
    description: `Need help or more information? Want to request a quote? Get in touch with our logistics experts. Simply email &#9993; or Call us &#9990; ${phone}`,
  },
};

export default [
  {
    redirect: true,
    from: '/en',
    to: '/',
    exact: true,
  },
  home,
  wwa,
  wwd,
  contactUs,
];
