import React from 'react';
import { Route } from 'react-router-dom';
import './NotFound.css'

function Status({ code, children }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}

const NotFound = () => {
  return (
    <Status code={404}>
      <div className="notFound">
        <h1>404</h1>
        <h2>Sorry, can’t find that.</h2>
      </div>
    </Status>
  );
}

export default NotFound;
