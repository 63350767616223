import React from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import Button from '../Button';

const TopHeading = ({
  title,
  subtitle,
  body,
  button,
  negative = false,
  hideSeperator = false,
  heading = {},
  ...rest
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      paddingX="10px"
      sx={{
        marginTop: negative ? '-40px' : 3,
      }}
      {...rest}
    >
      <Heading
        fontSize={[5, 6, 7]}
        color="darkgrey"
        fontFamily="heading"
        fontWeight="heading"
        as="h1"
        {...heading}
      >
        {title}
      </Heading>

      {subtitle ? (
        <Text
          fontSize={[1, 2, 3]}
          fontFamily="body"
          color="black"
          fontWeight="body"
        >
          {subtitle}
        </Text>
      ) : null}

      {!hideSeperator ? <hr /> : null}

      <Box width={[1, 1, 2 / 3]} alignItems="center" textAlign="center">
        {body ? (
          <Text
            fontSize={[1, 1, 2]}
            fontFamily="body"
            textAlign="center"
            marginBottom="20px"
            color="darkgrey"
            fontWeight="body"
            lineHeight="body"
          >
            {body}
          </Text>
        ) : null}

        {button ? (
          <Flex justifyContent="center">
            <Button {...button} rel="noopener" />
          </Flex>
        ) : null}
      </Box>
    </Flex>
  );
};

export default TopHeading;
