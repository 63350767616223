import React from 'react';
import { Image as Img } from 'rebass';
import AspectRatio from 'react-aspect-ratio';

const Image = ({ ratio, ...props }) => {
  if (ratio) {
    return (
      <AspectRatio ratio={ratio}>
        <Img {...props} />
      </AspectRatio>
    );
  }

  return <Img {...props} />;
};

export default Image;
