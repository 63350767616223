import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import routes from '../../routes';
import NotFound from '../../pages/NotFound';
import Navigation from '../Navigation';
import Footer from '../Footer';
import theme from './theme';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AppContext from './AppContext';
import Helmet from 'react-helmet';
import ScrollToTop from './ScrollToTop';
export { AppContext };
import { titleSuffix } from '../../config';
import GoogleAnalytics from './GoogleAnalytics';
// import Banner from '../Banner';

// wrapping/composing
// You can spread routeProps to make them available to your rendered Component
function HelmetRoute({ component: Component, head = {}, ...rest }) {
  return (
    <Route
      {...rest}
      render={routeProps => {
        return (
          <>
            <Helmet>
              {head.title && (
                <title>
                  {head.title}
                  {titleSuffix}
                </title>
              )}
              {head.description && (
                <meta name="description" content={head.description} />
              )}
            </Helmet>
            <Component {...routeProps} />
          </>
        );
      }}
    />
  );
}

const App = ({ context }) => {
  return (
    <AppContext.Provider value={{ ...context }}>
      <GoogleAnalytics />
      <ThemeProvider theme={theme}>
        <Navigation />
        {/* <Banner /> */}
        <Switch>
          {routes.map(route => {
            const { redirect, ...routeProps } = route;

            if (redirect) {
              return (
                <Redirect
                  key={`route-redirect-${routeProps.from}-${routeProps.to}`}
                  {...routeProps}
                />
              );
            }

            return (
              <HelmetRoute key={`route-${routeProps.path}`} {...routeProps} />
            );
          })}
          <Route component={NotFound} />
        </Switch>

        <ScrollToTop />
        <Footer />
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
