import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import Logo from './assets/lunalogistik-logo.svg';
import './navigation.css';
import { PhoneWhiteIcon } from '../Icons';
import { Link } from 'rebass';
import { CloseIcon, BurgerIcon } from '../Icons';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import cx from 'classnames';
import { Trans } from '@lingui/macro';
import {
  wwd,
  wwa,
  contactUs,
} from '../../routes';
import { phone } from '../../config';

const Navigation = () => {
  const location = useLocation();

  const [visiblePushMenu, setVisiblePushMenu] = useState(false);
  const [isFixed, setFixed] = useState(false);

  useEffect(() => {
    // if push menu was visible we hide it
    if (visiblePushMenu) {
      setVisiblePushMenu(false);
    }
  }, [location]);

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y < 0;
      if (isShow !== isFixed) setFixed(isShow);
    },
    [isFixed],
  );

  return (
    <nav
      id="navigation"
      className={cx(
        isFixed ? ['fixed', 'solid'] : null,
        'fixed',
      )}
    >
      <NavLink to="/" exact id="logo">
        <Logo className="logo" />
      </NavLink>
      <div className="menu">
        <ul>
          <li>
            <NavLink to={wwa.path} exact activeClassName="active">
              <Trans id="menu.first">Who we are</Trans>
            </NavLink>
          </li>

          <li>
            <NavLink to={wwd.path} exact activeClassName="active">
              <Trans id="menu.second">What we do</Trans>
            </NavLink>
          </li>

          <li>
            <NavLink to={contactUs.path} exact activeClassName="active">
              <Trans id="menu.fourth">Contact Us</Trans>
            </NavLink>
          </li>
        </ul>
      </div>

      {visiblePushMenu ? (
        <>
          <div
            className="push-menu-overlay"
            onClick={() => setVisiblePushMenu(false)}
          />
          <div className="push-menu">
            <div className="push-menu-header">
              <NavLink to="/" exact id="logo">
                <Logo className="logo" />
              </NavLink>

              <button
                className="btn-close"
                aria-label="burger menu toggler"
                onClick={() => setVisiblePushMenu(false)}
              >
                <CloseIcon />
              </button>
            </div>

            <ul className="main-menu">
              <li>
                <NavLink
                  to={wwa.path}
                  exact
                  activeClassName="active"
                  rel="noopener"
                >
                  <Trans id="menu.first">Who we are</Trans>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={wwd.path}
                  exact
                  activeClassName="active"
                  rel="noopener"
                >
                  <Trans id="menu.second">What we do</Trans>
                </NavLink>
              </li>

              <li>
                <NavLink
                  to={contactUs.path}
                  exact
                  activeClassName="active"
                  rel="noopener"
                >
                  <Trans id="menu.fourth">Contact Us</Trans>
                </NavLink>
              </li>
            </ul>

            <ul className="actions">
              <li>
                <Link
                  href={`tel:${phone}`}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <PhoneWhiteIcon
                    style={{
                      marginRight: '10px',
                      height: '18px',
                      width: '18px',
                      border: '2px solid white',
                      fill: 'white',
                      borderRadius: '100%',
                      padding: '5px',
                    }}
                  />
                  <span>{phone}</span>
                </Link>
              </li>
            </ul>
          </div>
        </>
      ) : null}

      <button
        className="btn-burger"
        aria-label="burger menu toggler"
        onClick={() => setVisiblePushMenu(true)}
      >
        <BurgerIcon />
      </button>
    </nav>
  );
};

// Navigation.propTypes = {
//   match: PropTypes.object.isRequired,
//   location: PropTypes.object.isRequired,
//   history: PropTypes.object.isRequired,
// };

export default Navigation;
