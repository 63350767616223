import React, { useContext } from 'react';
import Hero from '../../components/Hero';
import { SideBySide, TopHeading } from '../../components/Blocks';
import { Flex, Box, Heading, Text, Link } from 'rebass';
import heroImg from './assets/air-cargo-logistics-airplane-pallets-containers-freight.jpg';
import blockOne from './assets/international-cargo-transporter-antonov.jpg';
import blockTwo from './assets/cargo-aircraft-lunajets_team.jpg';
import './Home.css';
import useViewportSizes from 'use-viewport-sizes';
import { useTheme } from 'emotion-theming';
import Image from '../../components/Image';
import {
  PhoneIcon,
  MailIcon,
} from '../../components/Icons';
import { wwd, wwa } from '../../routes';
import { withI18n } from '@lingui/react';
import { t, Trans } from '@lingui/macro';
import Gui from '../WhoWeAre/assets/guillaume-launay-head-of-logistics-lunalogistik.jpg';
import { alain } from '../../mocks/agents';

// import AppContext from '../../components/App/AppContext'
// import { RequestFlight } from 'RequestFlight';
import { phone } from '../../config';


const isBrowser = process.env.BUILD_TARGET !== 'server'
const useIsomorphicLayoutEffect =
  isBrowser
    ? React.useLayoutEffect
    : React.useEffect;

const Home = props => {
  // const { client } = useContext(AppContext);
  const { breakpoints } = useTheme(); // retrieve theme context breakpoint
  const [vpWidth, , updateVpSizes] = useViewportSizes(); // viewport width

  useIsomorphicLayoutEffect(() => {
    updateVpSizes();
  }, [vpWidth]);

  const breakpoint = parseInt(breakpoints[1].replace(/\D/g));

  return (
    <div>
      <header>
        <Hero src={[heroImg]} alt="A view from inside an aircraft trunk with containers and pallets goods being loaded." />
        {/* <RequestFlight
          cargo
          phone={phone}
          client={client}
          messages={{
            titles: {
              title: 'Request your next Air Cargo flight',
              step1: 'Cargo Flight Details'
            },
            btns: {
              requestQuote: 'Request Quote',
              editRequest: 'Edit My Quote',
              needHelp: 'Need help? +41 22 989 08 27',
            },
            labels: {
              additionalNotes: 'Cargo Information',
              advisorMessage: 'an Cargo Advisor already started working on your request and will get back to you shortly.'
            },
            placeholders: {
              additionalNotes: `Type of Goods/Marchandises: ____ 
Total weight: ____ CBM: ____ Size (height/width/length): ____
Number of boxes: ____  
Other: ____`
            }
          }}
        /> */}
      </header>
      <Box pt={4} as="section">
        <TopHeading
          negative
          sx={{
            mb: 4,
          }}
          title={<Trans id="home.title">Aircraft brokerage</Trans>}
          subtitle={
            <Trans id="home.subtitle">
              Independent, Transparent & Personalised
            </Trans>
          }
          body={
            <Trans id="home.description">
              <p>
                Whatever your need, our team of experts is on hand to assist
                you. Private aviation transactions are full of obstacles and
                surprises that can prove costly. At LunaSolutions we are
                solution-oriented and will help you navigate the process of
                selling or acquiring your aircraft.
              </p>
              <p>
                LunaSolutions is a private company that positions itself as
                offering an unparalleled level of personalised service for its
                clients. We are not affiliated with a third party, nor operate
                any planes. We remain completely independent and neutral.
              </p>
            </Trans>
          }
        />
      </Box>
      <Box as="section">
        <SideBySide
          image={blockOne}
          title={<Trans id="home.wwa.title">Who we are</Trans>}
          alt="International Cargo transporter Antonov aircraft in the airport tarmac unloading cargo goods beeing unloaded by several workers."
          button={{
            link: wwa.path,
            text: <Trans id="home.wwa.button">More Information</Trans>,
          }}
          subtitle={
            <Trans id="home.wwa.subtitle">
              Experts in the sale and acquisition of business jets
            </Trans>
          }
          body={
            <Trans id="home.wwa.description">
              LunaSolutions is dedicated to helping private and corporate
              clients with the acquisition or sale of their private jet. We are
              a private, independent and global company, with offices in Geneva
              and London.
            </Trans>
          }
        />
        {/** TODO: sidebyside component bellow, replace the strings to Trans as above */}
        <SideBySide
          reverse
          image={blockTwo}
          alt="Container Loader Ground Support Equipment (GSE) supporting the cargo good loading operations of two containers and pallets of an aircraft on the ground of the airport tarmac."
          button={{
            link: wwd.path,
            text: <Trans id="home.wwd.button">More Information</Trans>,
          }}
          title={<Trans id="home.wwd.title">What we do</Trans>}
          subtitle={
            <Trans id="home.wwd.subtitle">
              A boutique firm offering private aviation expertise.
            </Trans>
          }
          body={
            <Trans id="home.wwd.description">
              The mission of LunaSolutions is to provide objective advice and
              operational help for private, corporate clients or governments
              looking to acquire, sell or trade private jets. As a boutique
              firm, we offer individualised consultancy services.
            </Trans>
          }
        />
      </Box>

      <section className="block" id="dedicated-team">
        <Flex
          justifyContent="center"
          flexWrap="wrap"
          mx={[2, 2, '10%']}
          my={[2, 2, 4]}
        >
          <Box
            width={[1, 1, 1 / 2]}
            display={['none', 'none', 'unset']}
            justifyContent="center"
            px={4}
          >
            <Image
              src={Gui}
              alt="Portrait photo of Guillaume Launay, Group Managing Director of LunaLogistik"
              sx={{ 
                objectFit: 'cover',
                maxHeight: '500px',
                display: 'block',
                margin: '0 auto'
              }}
            />
          </Box>
          <Box
            width={[1, 1, 1 / 2]}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Box px={[0, 0, 4]} alignItems={['center', 'center', 'flex-start']}>
              <Heading
                fontSize={[5, 6]}
                color="darkgrey"
                fontFamily="heading"
                fontWeight="heading"
                as="h2"
                textAlign={['center', 'center', 'left']}
                sx={{
                  marginBottom: 3,
                }}
              >
                <Trans id="home.team.title">Dedicated Team</Trans>
              </Heading>

              <Text
                fontSize={[3, 3, 4]}
                fontFamily="body"
                color="black"
                fontWeight="body"
                marginBottom="20px"
                textAlign={['center', 'center', 'left']}
                sx={{
                  fontStyle: 'italic',
                  lineHeight: 1.5,
                }}
              >
                <Trans id="home.team.description">
                  “The world of private jet transactions is largely based on
                  relationships of trust. Trust that has been purposefully
                  earned over time.”
                </Trans>
              </Text>

              <Flex
                justifyContent="center"
                display={['flex', 'flex', 'none']}
                my={3}
              >
                <Image
                  alt="Portrait photo of Guillaume Launay, Group Managing Director of LunaLogistik"
                  src={Gui}
                  sx={{
                    objectFit: 'cover',
                    width: 160,
                    height: 160,
                    borderRadius: '100%',
                    boxShadow: '1px 10px 10px 0px rgba(0,0,0,.1)',
                  }}
                />
              </Flex>

              <Text
                fontSize={[0, 1, 2]}
                fontFamily="body"
                color="black"
                fontWeight="600"
                lineHeight="body"
                fontSize={1}
                textAlign={['center', 'center', 'left']}
                sx={{
                  display: 'flex',
                  flexDirection: ['column', 'column', 'row'],
                }}
              >
                <span>
                  <Trans id="wwa.team.gui.name"/>
                </span>
              </Text>

              <Link
                href={`tel:${alain.phone}`}
                rel="noopener"
                variant="footerLink"
                sx={{
                  marginY: 2,
                  display: 'flex',
                  flexWrap: ['wrap', 'wrap', 'nowrap'],
                  flexDirection: ['column', 'column', 'row'],
                  '> svg': {
                    marginRight: [0, 0, '5px'],
                  },
                }}
              >
                <PhoneIcon style={{ width: 18, height: 18 }} />
                <span>{alain.phone}</span>
              </Link>
              <Link
                href={`mailto:${alain.email}`}
                rel="noopener"
                variant="footerLink"
                sx={{
                  marginY: 2,
                  display: 'flex',
                  flexWrap: ['wrap', 'wrap', 'nowrap'],
                  flexDirection: ['column', 'column', 'row'],
                  '> svg': {
                    marginRight: [0, 0, '5px'],
                  },
                }}
              >
                <MailIcon style={{ width: 18, height: 18 }} />
                <span>{alain.email}</span>
              </Link>
            </Box>
          </Box>
        </Flex>
      </section>

    </div>
  );
};

export default withI18n()(Home);
