// config.js
module.exports = {
  phone: '+41 22 989 08 27',
  website: 'lunalogistik.com',
  email: 'geneva@lunalogistik.com',
  offices: {
    geneva: {
      email: 'geneva@lunalogistik.com',
      phone: '+41 22 989 08 27',
      address: '29 Rue Lect, Geneva Airport, Meyrin, Switzerland',
      geo: {
        lat: 46.2281244,
        long: 6.1126745,
      },
    },
    london: {
      email: 'london@lunalogistik.com',
      phone: '+44 870 820 0288',
      address: '42 Berkeley Square, London, United Kingdom',
      geo: {
        lat: 46.2280953,
        long: 6.1126746,
      },
    },
  },
  titleSuffix: ' | Luna Logistik',
  GA_TRACKING_ID: 'UA-167115830-1',
};
