import React from 'react';
import { Box, Flex, Text, Heading, Link } from 'rebass';
import SwitzerlandFlag from './swiss_flag.svg';
import { PhoneIcon, MailIcon, PinIcon, GlobeIcon } from '../Icons';
import bg from './assets/bg.jpg';
import { Trans } from '@lingui/macro';
import Image from '../Image';
import nbaa from './assets/nbaa.png';
// import aircharterassoc from './assets/aircharterassoc.png';
import argus from './assets/argus.png';
import ebaa from './assets/ebaa.png';
import { offices, website } from '../../config';

const websites = {
  company1: "lunajets.com",
  company2: "lunagroupcharter.com",
  company3: "lunaaircraftsolutions.com",
}

const Footer = props => {
  return (
    <footer
      style={{
        backgroundImage: `url(${bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center top',
        minHeight: '420px',
      }}
    >
      <Box
        mx={2}
        sx={{
          display: 'grid',
          gridGap: '40px',
          gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
        }}
      >
        <Box mx={2} px={0} my={2}>
          <Flex flexDirection="column">
            <Box marginBottom="10px">
              <Heading as="h5" marginBottom="10px" variant="footerTitle">
                <Trans id="footer.company1.title">LunaSolutions</Trans>
              </Heading>
              <Text variant="footerText">
                <Trans id="footer.company1.description">
                  LunaSolutions specialises in finding the best solution for
                  those looking to acquire or sell a private jet. We are a
                  private and independent company.
                </Trans>
              </Text>
              <Link
                href={`//www.${websites.company1}`}
                rel="noopener"
                variant="footerLink"
              >
                <GlobeIcon style={{ width: 18, height: 18 }} />
                {websites.company1}
              </Link>
            </Box>

            <Box marginBottom="10px">
              <Heading as="h5" marginBottom="10px" variant="footerTitle">
                <Trans id="footer.company2.title">LunaJets</Trans>
              </Heading>
              <Text variant="footerText">
                <Trans id="footer.company2.description">
                  LunaSolutions is to benefit from LunaJets extensive market
                  intelligence and experience including deep operational
                  knowledge.
                </Trans>
              </Text>
              <Link
                href={`//www.${websites.company2}`}
                target="_blank"
                rel="noopener"
                variant="footerLink"
              >
                <GlobeIcon style={{ width: 18, height: 18 }} />
                {websites.company2}
              </Link>
            </Box>

            <Box marginBottom="10px">
              <Heading as="h5" marginBottom="10px" variant="footerTitle">
                <Trans id="footer.company3.title">LunaJets</Trans>
              </Heading>
              <Text variant="footerText">
                <Trans id="footer.company3.description">
                  LunaSolutions is to benefit from LunaJets extensive market
                  intelligence and experience including deep operational
                  knowledge.
                </Trans>
              </Text>
              <Link
                href={`//www.${websites.company3}`}
                target="_blank"
                rel="noopener"
                variant="footerLink"
              >
                <GlobeIcon style={{ width: 18, height: 18 }} />
                {websites.company3}
              </Link>
            </Box>
            
          </Flex>
        </Box>
        <Box mx={2} px={0} my={[1, 1, 2]}>
          <Flex flexDirection="column">
            <Box marginBottom="30px">
              <Heading as="h5" marginBottom="10px" variant="footerTitle">
                <Trans id="footer.office1.title">Geneva Office</Trans>
              </Heading>
              <Link
                href={`geo:${offices.geneva.geo.lat},${offices.geneva.geo.long}`}
                rel="noopener"
                variant="footerLink"
                style={{ marginBottom: '10px'}}
              >
                <PinIcon style={{ width: 18, height: 18 }} />
                <span>{offices.geneva.address}</span>
              </Link>
              <Link
                href={`tel:${offices.geneva.phone}`}
                rel="noopener"
                variant="footerLink"
                sx={{ margin: '5px' }}
              >
                <PhoneIcon style={{ width: 18, height: 18 }} />
                <span>{offices.geneva.phone}</span>
              </Link>
              <Link
                href={`mailto:${offices.geneva.email}`}
                rel="noopener"
                variant="footerLink"
              >
                <MailIcon style={{ width: 18, height: 18 }} />
                <span>{offices.geneva.email}</span>
              </Link>
            </Box>

            <Box marginBottom="30px">
              <Heading as="h5" marginBottom="10px" variant="footerTitle">
                <Trans id="footer.office2.title">London Office</Trans>
              </Heading>

              <Link
                href={`geo:${offices.london.geo.lat},${offices.london.geo.long}`}
                rel="noopener"
                variant="footerLink"
                style={{ marginBottom: '10px'}}
              >
                <PinIcon style={{ width: 18, height: 18 }} />
                <span>{offices.london.address}</span>
              </Link>
              <Link
                href={`tel:${offices.london.phone}`}
                rel="noopener"
                variant="footerLink"
                sx={{ margin: '5px' }}
              >
                <PhoneIcon style={{ width: 18, height: 18 }} />
                <span>{offices.london.phone}</span>
              </Link>
              <Link
                href={`mailto:${offices.london.email}`}
                rel="noopener"
                variant="footerLink"
              >
                <MailIcon style={{ width: 18, height: 18 }} />
                <span>{offices.london.email}</span>
              </Link>
            </Box>
          </Flex>
        </Box>
        <Box mx={2} px={0} my={[1, 1, 2]}>
          <Flex flexDirection="column">
            <Box marginBottom="10px">
              <Heading as="h5" marginBottom="10px" variant="footerTitle">
                <Trans id="footer.member.title">Member & Certifications</Trans>
              </Heading>
            </Box>
            <Flex flexWrap={'wrap'}>
              <Link
                href="//nbaa.org/"
                display="flex"
                width={1}
                my={2}
                target="_blank"
                rel="nofollow"
                style={{ marginBottom: '40px'}}
              >
                <Image src={nbaa} style={{ objectFit: 'contain' }} />
              </Link>
              <Link
                href="//www.argus.aero/aviation-due-diligence/certified-broker/"
                display="flex"
                width={1}
                my={2}
                target="_blank"
                rel="nofollow"
                style={{ marginBottom: '40px'}}
              >
                <Image src={argus} style={{ objectFit: 'contain' }} />
              </Link>
              <Link
                href="//www.ebaa.org/"
                display="flex"
                width={1}
                my={2}
                target="_blank"
                rel="nofollow"
              >
                <Image src={ebaa} style={{ objectFit: 'contain' }} />
              </Link>
            </Flex>
          </Flex>
        </Box>
      </Box>

      {/* BOTTOM */}
      <Flex mx={[1, 1, 3]} py={2} flexWrap="wrap" justifyContent="center">
        <Box px={2} width={[1, 1, 4 / 12]}></Box>
        <Box px={2} my={2} width={[1, 1, 4 / 12]}>
          <Flex alignItems="center" justifyContent="center">
            <SwitzerlandFlag
              style={{ width: '18px', height: '18px', marginRight: '5px' }}
            />
            <Text
              fontFamily="Raleway"
              fontWeight="600"
              color="lightgrey"
              fontSize={0}
              textAlign="center"
              sx={{
                textTransform: 'uppercase',
              }}
            >
              <Trans id="footer.swiss.based.company">
                A Swiss-based company
              </Trans>
            </Text>
          </Flex>
        </Box>
        <Box px={2} my={2} width={[1, 1, 4 / 12]}>
          <Text
            fontFamily="Raleway"
            fontWeight="600"
            fontSize={0}
            color="lightgrey"
            textAlign={['center', 'center', 'right']}
            sx={{
              textTransform: 'uppercase',
            }}
          >
            <Trans id="footer.copyright.lunajets">
              © 2023 LunaJets • Aircraft Brokerage
            </Trans>
          </Text>
        </Box>
      </Flex>
    </footer>
  );
};

export default Footer;
