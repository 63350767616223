import React from 'react';
import { Link as LinkRebass, Button as Btn } from 'rebass';
import PropTypes from 'prop-types';
import IconCaret from './icons/arrow.svg';
import IconPlus from './icons/plus.svg';
import IconMail from './icons/mail.svg';
import IconMinus from './icons/minus.svg';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

const Button = ({
  intent = 'click',
  text,
  hideIcon = false,
  active = false,
  icon,
  className,
  children,
  link,
  variant = 'primary',
  loading = false,
  disabled = false,
  ...props
}) => {
  const getIcon = (() => {
    if (hideIcon) return null;
    if (icon) return icon;
    if (intent === 'click') return <IconCaret />;
    if (intent === 'toggle') return active ? <IconMinus /> : <IconPlus />;
    if (intent === 'submit') return <IconMail />;
    return <IconCaret />;
  })();

  const body = (
    <>
      {loading ? (
        <span style={{ marginRight: '10px' }}>
          <Loader
            type="Oval"
            color={disabled ? '#ffffff' : '#2d3d51'}
            height={18}
            width={18}
          />
        </span>
      ) : (
        getIcon
      )}

      {text ? <span>{text}</span> : children}
    </>
  );

  if (link) {
    return (
      <LinkRebass
        as={Link}
        variant="cta"
        className={cx(className, active ? 'active' : null)}
        to={link}
        {...props}
      >
        {body}
      </LinkRebass>
    );
  }

  return (
    <Btn
      variant={variant}
      className={cx(
        className,
        active ? 'active' : null,
        disabled ? 'disabled' : null,
      )}
      {...props}
    >
      {body}
    </Btn>
  );
};

Button.propTypes = {
  intent: PropTypes.oneOf(['submit', 'toggle', 'click']),
  hideIcon: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  active: PropTypes.bool,
  icon: PropTypes.node,
};

export default Button;
