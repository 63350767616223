import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { name, version } from '../../package.json';
import rootReducer from './reducers';

export default (initialState, helpers) => {
  const __DEV__ = process.env.NODE_ENV === 'development';
  const middleware = [thunk.withExtraArgument(helpers || {})];
  let enhancer;

  if (__DEV__) {
    // middleware.push(createLogger());

    // https://github.com/zalmoxisus/redux-devtools-extension#14-using-in-production
    const composeEnhancers = require('redux-devtools-extension/developmentOnly').composeWithDevTools(
      {
        // Options: https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/API/Arguments.md#options
        name: `${name}@${version}`,
      },
    );

    // https://redux.js.org/docs/api/applyMiddleware.html
    enhancer = composeEnhancers(applyMiddleware(...middleware));
  } else {
    enhancer = applyMiddleware(...middleware);
  }

  const store = createStore(rootReducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept('./reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('./reducers').default),
    );
  }

  return store;
};
