import React from 'react';
import Hero from '../../components/Hero';
import { TopHeading } from '../../components/Blocks';
import Button from '../../components/Button';
import { Flex, Box, Heading, Text, Card } from 'rebass';
import Image from '../../components/Image';
import heroImg from './assets/cargo-aircraft-loaded-with-containers-and-pallets-from-left-side.jpg';
import './WhoWeAre.css';
import { Trans } from '@lingui/macro';
import { useTheme } from 'emotion-theming';
import Eymeric from './assets/eymeric-segard-founder-and-chairman-lunalogistik.jpg';
import Alain from './assets/alain-leboursier-managing-director-lunalogistik.png';
import Gui from './assets/guillaume-launay-head-of-logistics-lunalogistik.jpg';
import Lina from './assets/arthur-guibert-head-of-trading-lunalogistik.jpg';
import { contactUs } from '../../routes';

const WhoWeAre = () => {
  const { breakpoints } = useTheme();

  return (
    <div>
      <header>
        <Hero src={[heroImg]} alt="Container Loader Ground Support Equipment (GSE) supporting the cargo good loading operations of two containers and pallets of an aircraft on the ground of the airport tarmac from the right side." />
      </header>

      <Box as="section" pt={4}>
        <TopHeading
          negative
          sx={{
            mb: 4,
          }}
          title={<Trans id="wwa.title">Who We Are</Trans>}
          body={
            <Trans id="wwa.body">
              Increasingly more often involved in helping clients navigate the
              complex process of buying or selling a private jet, we identified
              a gap in the market for a transparent, neutral and client focused
              service, that they needed, but were not finding.
              <br />
              As LunaJets had already operated for 11 years under these same
              core values, LunaSolutions was launched to solely represent our
              client, providing fully independent private aviation expert
              advice, aimed at answering their needs.
            </Trans>
          }
        />
      </Box>

      {/* Record */}
      <section className="block" id="track-record">
        <Flex
          flexDirection={['column', 'column', 'row']}
          paddingX={[0, 0, '120px']}
          paddingY={[2, 2, 2]}
        >
          <Box
            paddingX={[3, 3, 4]}
            width={[1, 1, 1 / 2]}
            order={[2, 2, 1]}
            className="record-paragraph"
          >
            <Heading
              fontSize={[5, 6, 7]}
              color="darkgrey"
              fontFamily="heading"
              fontWeight="heading"
              as="h2"
            >
              <Trans id="www.paragraph.title">
                A solid track record in private aviation
              </Trans>
            </Heading>

            <Box
              as="hr"
              sx={{
                margin: ['20px 0 !important', 'inherit', 'inherit'],
              }}
            />
            <Text as="p" variant="blockText">
              <Trans id="wwa.paragraph.top">
                Eymeric Segard, Founder and CEO of LunaJets and LunaSolutions,
                has over a decade of experience in private aviation. Last year
                alone, his air charter brand organised over 6,000 flights and is
                now the number one, independent private jet booking company in
                Europe. While headquartered in Geneva, 85% of LunaJets flights
                take place outside of Switzerland, illustrating the global scope
                of the company.
                <br />
                LunaSolutions is to benefit from LunaJets' extensive market
                intelligence and experience, including:
              </Trans>
            </Text>

            <ul className="benefits">
              <Trans id="wwa.paragraph.bullets">
                <li>deep operational knowledge</li>
                <li>ten years of data</li>
                <li>cost per hour statistics</li>
                <li>aircraft manufacturer and model popularity index</li>
                <li>
                  operators’ service ratings and privileged access to the best
                  ones, and
                </li>
                <li>a neutral positioning</li>
              </Trans>
            </ul>

            <Text variant="blockText">
              <Trans id="wwa.paragraph.bottom">
                <p>
                  that confer to LunaSolutions a legitimate and objective point
                  of view.
                </p>

                <p>
                  LunaSolutions can also provide clients looking to acquire an
                  aircraft with realistic financial forecasts as to what
                  revenues can be generated through renting their jet.
                </p>
              </Trans>
            </Text>
          </Box>
          <Box
            paddingX={[3, 3, 4]}
            paddingY={[3, 3, 4]}
            width={[1, 1, 1 / 2]}
            order={[1, 1, 2]}
            className="highlight"
            align-self="center"
          >
            <Text
              fontSize={[4, 5, 6]}
              fontFamily="heading"
              textAlign="center"
              marginBottom="20px"
              color="darkgrey"
              fontWeight="heading"
              textAlign="left"
              width={[1, 1, 4 / 5]}
            >
              <Trans id="wwa.bigtext.right">
                With over 6,000 flights organised last year, LunaJets leads the
                industry
              </Trans>
            </Text>
          </Box>
        </Flex>
      </section>

      {/* Advantages */}
      <section className="block" id="advantages">
        <Flex
          justifyContent="center"
          paddingX={[0, 0, '120px']}
          paddingY={[5, 2, 2]}
          className="advantages-wrapper"
          flexDirection={['column', 'column', 'row']}
        >
          <Box paddingY={[3, 4, 6]} paddingX={[3, 3, 4]} width={[1, 1, 1 / 3]}>
            <Text as="h2" variant="blockOpenContentHeading">
              <Trans id="wwa.blocks.left.title">Privately Owned</Trans>
            </Text>

            <Box
              as="hr"
              sx={{
                margin: ['20px 0 !important', 'inherit', 'inherit'],
                width: '20px',
                backgroundColor: 'white',
              }}
            />

            <Text variant="blockTextContentOpenWhite" textAlign="justify">
              <Trans id="wwa.blocks.left.body">
                Because LunaSolutions is a private company and is not tied to
                any third party, we can conduct business with any and all
                partners around the globe.
              </Trans>
            </Text>
          </Box>

          <Box paddingY={[3, 4, 6]} paddingX={[3, 3, 4]} width={[1, 1, 1 / 3]}>
            <Text as="h2" variant="blockOpenContentHeading">
              <Trans id="wwa.blocks.middle.title">
                Independent & market neutral
              </Trans>
            </Text>

            <Box
              as="hr"
              sx={{
                margin: ['20px 0 !important', 'inherit', 'inherit'],
                width: '20px',
                backgroundColor: 'white',
              }}
            />

            <Text variant="blockTextContentOpenWhite" textAlign="justify">
              <Trans id="wwa.blocks.middle.body">
                LunaSolutions wishes to remain independent and neutral. It is
                dedicated solely to advising its client and will only accept
                compensation from our clients.
              </Trans>
            </Text>
          </Box>

          <Box paddingY={[3, 4, 6]} paddingX={[3, 3, 4]} width={[1, 1, 1 / 3]}>
            <Text as="h2" variant="blockOpenContentHeading">
              <Trans id="wwa.blocks.right.title">
                Transparent and customer-centric
              </Trans>
            </Text>

            <Box
              as="hr"
              sx={{
                margin: ['20px 0 !important', 'inherit', 'inherit'],
                width: '20px',
                backgroundColor: 'white',
              }}
            />

            <Text variant="blockTextContentOpenWhite" textAlign="justify">
              <Trans id="wwa.blocks.right.body">
                While LunaSolutions is affiliated with neither aircraft
                manufacturers nor operators, we have a privileged access to
                these key players as well as to potential buyers. Thanks to a
                decade of client services, we are frequently approached by our
                customers interested in acquiring or selling a business jet.
              </Trans>
            </Text>
          </Box>
        </Flex>
      </section>

      {/* Quote */}
      <section className="block" id="quote">
        <Flex
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          paddingX="10px"
        >
          <Box
            width={[4 / 5, 3 / 4, 2 / 3]}
            alignItems="center"
            textAlign="center"
            className="wwa-quote"
          >
            <Text variant="blockQuote" color="darkgrey" paddingTop="20px">
              <Trans id="wwa.quote.sentence">
                Our history of independence and of having never been affiliated
                with a particular aircraft manufacturer or operator, makes
                LunaSolutions a powerful partner for clients. We do not have any
                conflicts of interest and will always retain an objective point
                of view.
              </Trans>
            </Text>
            <Text
              fontFamily="body"
              fontSize={[0, 1, 1]}
              fontWeight="600"
              lineHeight="body"
              fontStyle="italic"
              color="darkgrey"
              paddingTop="15px"
              paddingBottom="20px"
            >
              <Trans id="wwa.quote.author">
                — Eymeric Segard, CEO & Founder
              </Trans>
            </Text>
          </Box>
        </Flex>
      </section>

      {/* Teams */}
      <Box as="section" className="block" id="team" translate={[240, 200, 120]} marginBottom={0}>
        <Flex
          className="team-banner"
          flexDirection="column"
          paddingX={[3, 4, 4]}
          paddingBottom={[3, 4, 4]}
          paddingTop={['60px', '80px', '80px']}
          marginX={[0, 0, 3]}
          minHeight={['500', '500', '500']}
        >
          <Heading
            fontSize={[5, 6, 7]}
            width={[1, 1, 3 / 4]}
            color="white"
            fontFamily="heading"
            fontWeight="heading"
            as="h2"
          >
            <Trans id="wwa.team.title">
              An international team at your service
            </Trans>
          </Heading>

          <Box
            as="hr"
            sx={{
              margin: ['20px 0 !important', 'inherit', 'inherit'],
              backgroundColor: 'white',
            }}
          />
          <Box width={[1, 1, 3 / 4]}>
            <Text
              maxWidth="70%"
              fontSize={2}
              fontFamily="body"
              marginBottom="20px"
              color="white"
              fontWeight="body"
              lineHeight="body"
            >
              <Trans id="wwa.team.paragraph">
                From offices in Geneva and London, the Sales Experts of
                LunaSolutions operate around the globe.
              </Trans>
            </Text>

            <Flex>
              <Button
                link={contactUs.path}
                active
                text={<Trans>Contact Us</Trans>}
                rel="noopener"
                className="btn-contact-us"
              />
            </Flex>
          </Box>
        </Flex>

        <Flex
          className="team-members"
          justifyContent="center"
          alignItems="center"
          flexDirection={['column', 'column', 'row']}
        >
          <Card
            width={['100%', '100%', '500px']}
            padding={[3, 4, 4]}
            marginX={['unset', 'unset', 4]}
          >
            <Image
              width="100%"
              ratio="3/4"
              src={Eymeric}
              alt="Portrait photo of Mister Eymeric Segard, Founder and Chairman of LunaLogistik"
              sx={{ objectFit: 'cover' }}
              mb="-4px"
            />
            <Box
              backgroundColor="#f7f7f7"
              padding={4}
              justifyContent="center"
              width="100%"
              height={['auto', 'auto', 600]}
            >
              <Text
                fontSize={[3, 4, 5]}
                fontFamily="heading"
                fontWeight="heading"
                color="darkgrey"
                as="h3"
              >
                <Trans id="wwa.team.eymeric.name">Eymeric Segard</Trans>
              </Text>
              <Text as="h4">
                <Trans id="wwa.team.eymeric.title">CEO & Founder</Trans>
              </Text>

              <Box
                as="hr"
                sx={{
                  margin: ['20px 0 !important', 'inherit', 'inherit'],
                }}
              />

              <Text
                variant="newsCardBody"
                lineHeight="1.85"
                textAlign="justify"
              >
                <Trans id="wwa.team.eymeric.about">
                  Eymeric founded LunaJets in 2007 reselling empty leg flights.
                  Since 2008 and an expansion into the charter market, the
                  company has enjoyed 35% of compounded annual growth. Eymeric’s
                  innovative mindset, multinational perspective and
                  customer-centric focus has transformed LunaJets from a
                  two-person start-up into a global brand that organises over
                  6,000 annual flights. In 2019, Eymeric launched LunaSolutions
                  to address growing requests from clients for help in aircraft
                  acquisitions and trading, and a desire to explore new venues
                  in the private aviation segment. Eymeric obtained his BA in
                  Economics from Brown University and speaks five languages
                  fluently.
                </Trans>
              </Text>
            </Box>
          </Card>

          <Card
            width={['100%', '100%', '500px']}
            paddingX={[3, 4, 4]}
            paddingTop={[3, 4, 4]}
            paddingBottom={[0,0,0]}
            marginX={['unset', 'unset', 4]}
          >
            <Image
              width="100%"
              ratio="3/4"
              src={Gui}
              alt="Portrait photo of Mister Guillaume Launay, Head of Logistics of LunaLogistik"
              sx={{ objectFit: 'cover' }}
              mb="-4px"
            />
            <Box
              backgroundColor="#f7f7f7"
              padding={4}
              justifyContent="center"
              width="100%"
              height={['auto', 'auto', 600]}
            >
              <Text
                fontSize={[3, 4, 5]}
                fontFamily="heading"
                fontWeight="heading"
                color="darkgrey"
                as="h3"
              >
                <Trans id="wwa.team.gui.name">Alain Leboursier</Trans>
              </Text>

              <Text as="h4">
                <Trans id="wwa.team.gui.title">
                  Director of Sales & Business Development
                </Trans>
              </Text>

              <Box
                as="hr"
                sx={{
                  margin: ['20px 0 !important', 'inherit', 'inherit'],
                }}
              />

              <Text
                variant="newsCardBody"
                lineHeight="1.85"
                textAlign="justify"
              >
                <Trans id="wwa.team.gui.about">
                  Alain has seven years of experience in private aviation. Since
                  2017 he holds the position of Head of Sales at LunaJets. Under
                  his leadership, Alain virtually doubled the size of LunaJets’
                  commercial team and its revenue. While Alain has a natural
                  ability to understand and interact with HNWIs of all cultures,
                  he is also the first to roll up his sleeves to solve a problem
                  with his team. Alain received his BSc. in International
                  Hospitality Management from Ecole hôtelière de Lausanne with a
                  minor in Finance, making him both client-focused and numbers
                  driven.
                </Trans>
              </Text>
            </Box>
          </Card>
        </Flex>

      
        <div style={{ position: 'static', height: '-100px' }}></div>
      </Box>
    </div>
  );
};

export default WhoWeAre;
